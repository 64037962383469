import React from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Logo } from 'components/atoms';

import './login-form.scss';
/* Logo used in the login page in SVG format  */
import logoSiconia from 'assets/img/corporate/siconia.svg';

const LoginForm = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <div className="login-form is-family-primary">
      <Logo logo={logoSiconia} width="280px" />

      <div className="login-form-content">
        <h2 className="login-welcome-phrase">{t('login.welcome_phrase')}</h2>
        <h3 className="login-subtitle">{t('login.subtitle')}</h3>
      </div>

      <button
        type="submit"
        className="button is-primary is-rounded login-button has-text-weight-medium"
        onClick={() => keycloak.login()}
      >
        {t('login.login_button')}
      </button>
    </div>
  );
};

export default LoginForm;
