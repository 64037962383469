import React from 'react';
import { useAppSelector } from 'hooks';
import { Navigate, useLocation } from 'react-router-dom';

interface PermissionPrivateRouteProps {
  requiredPermission: string;
  children: JSX.Element;
}

const PermissionPrivateRoute = ({ requiredPermission, children }: PermissionPrivateRouteProps) => {
  const { useCasePermissions } = useAppSelector((state) => state.session.dataContext);
  const location = useLocation();

  const hasPermission = useCasePermissions
    .map((userPerm) => userPerm.toLowerCase())
    .includes(requiredPermission.toLowerCase());

  if (useCasePermissions.length === 0) {
    return null;
  }

  return hasPermission ? children : <Navigate to="/" state={{ from: location }} replace />;
};

export default PermissionPrivateRoute;
