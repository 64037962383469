import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from 'App';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'store';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n/i18n';
import keycloak from 'keycloack';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import 'assets/styles/app.scss';
import { Loader } from 'components/atoms';

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <ReduxProvider store={store}>
    <I18nextProvider i18n={i18n}>
      <ReactKeycloakProvider authClient={keycloak} LoadingComponent={<Loader />}>
        <App />
      </ReactKeycloakProvider>
    </I18nextProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
