import { decimalToRoundedTenthPercent, convertToHour, roundToTenth } from 'helpers';
import { apiCall } from 'services';

/* Function to get the final phase after permutation according to the permutation and initial phase.
   For three-phased smartmeter the 3 phases order is indicated */
export const getPermutedPhase = (phase: '1' | '2' | '3' | '1-2-3', permutation: 0 | 1 | 2) => {
  if (permutation === 0) {
    return '0';
  }
  /* Specific case for 3 phases smartmeter*/
  if (phase === '1-2-3') {
    return permutation === 1 ? '2-3-1' : '3-1-2';
  }

  let res = permutation + parseInt(phase);
  if (res > 3) {
    res -= 3;
  } else if (res < 1) {
    res += 3;
  }
  return res.toString();
};

export const getListPermutations = async (
  rebalancingId: string,
  datasetId: string,
  smartmeters: SmartMeter[],
  feeders: SecondarySubstationFeeder[] | null
): Promise<Permutation[]> => {
  const {
    data: { data: rebalancingPlan },
  } = await apiCall.getRebalancingPlan(datasetId, rebalancingId);
  const smartmetersId =
    rebalancingPlan &&
    rebalancingPlan
      .filter((perm: RebalancingPermutationApi) => perm.permutation)
      .map((el: RebalancingPermutationApi) => el.sensor);

  const listePermutations =
    smartmetersId &&
    smartmeters
      .filter((sm) => smartmetersId.includes(sm.identifier))
      .map((sm) => {
        const { permutation } = rebalancingPlan.find(
          (el: RebalancingPermutationApi) => el.sensor === sm.identifier
        );
        const intialPhase = sm.phase1 === '4' ? '1-2-3' : sm.phase1;
        return {
          feeder: feeders?.find((feeder) => sm.feeder === feeder.identifier)?.name ?? sm.feeder,
          smarmeterId: sm.identifier,
          smartmeter: sm.name,
          phase: intialPhase,
          permutation,
          finalPhase: getPermutedPhase(intialPhase, permutation),
        } as Permutation;
      });
  return listePermutations;
};

export const getRebalancingMetrics = async (datasetId: string, rebalancingId: string) => {
  const response = await apiCall.getRebalancingMetrics(datasetId, rebalancingId);
  if (response.data && response.data.data) {
    return response.data.data.length ? response.data.data[0] : null;
  } else {
    throw new Error('Response null or unexpected response structure)');
  }
};

export const convertRebalancingMetricsToTableRow = (rebalancingMetrics: RebalancingMetricsApi) => {
  return {
    rebalancing: true,
    imbalanceRate: decimalToRoundedTenthPercent(rebalancingMetrics.imbalanceRateAfter),
    maxSecSubLoadRate: decimalToRoundedTenthPercent(rebalancingMetrics.maxSecSubLoadRate),
    maxTimeFeederOvercurrent: convertToHour(rebalancingMetrics.maxTimeFeederOvercurrent),
    minCapacity: roundToTenth(rebalancingMetrics.minCapacity?.magnitude),
    nbSmVImbalanced: roundToTenth(rebalancingMetrics.nbSmVImbalanced),
    nbSmVOutOfRange: roundToTenth(rebalancingMetrics.nbSmVOutOfRange),
    overFixedMinCapacityNb: roundToTenth(rebalancingMetrics.overFixedMinCapacityNb),
    totalTimeFeedersOvercurrent: convertToHour(rebalancingMetrics.totalTimeFeedersOvercurrent),
    totalTimeSmVOutOfRange: convertToHour(rebalancingMetrics.totalTimeSmVOutOfRange),
  } as RebalancingTableRow;
};
