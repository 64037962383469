import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import Layout from 'components/organisms/Layout/Layout';
import { Navigate, Outlet } from 'react-router-dom';

const RedirectOnAuth = () => {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated) {
    return <Navigate to="/mapping" replace />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default RedirectOnAuth;
