import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useAppDispatch } from 'hooks';
import { apiCall } from 'services';
import { login } from 'store/actions';
import { router } from 'router/router';
import { Loader } from 'components/atoms';

const App = () => {
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();
  const { token, authenticated } = keycloak;

  const convertProcessGroup = (
    processGroup: ProcessingGroup[],
    processName: string
  ): ProcessingSensor[] => {
    return processGroup
      .filter((el: ProcessingGroup) => {
        return el.parent === processName;
      })
      .map((el: ProcessingGroup) => ({ processing: el.processing, sensor: el.sensor }));
  };

  useEffect(() => {
    if (authenticated && token !== undefined) {
      const loadData = async () => {
        const { data: prefs } = await apiCall.getPreferences();
        if (prefs) {
          const { integrator, dso, dataset } = prefs;
          const {
            data: { permissions: perms },
          } = await apiCall.getPermissons();
          const {
            data: { data: datasetObj },
          } = await apiCall.getDatasets(integrator, dso, dataset);
          const {
            data: { data: processGroup },
          } = await apiCall.getProcessingGroup(integrator, dso, dataset);
          const dataContext: DataContext = {
            datasetId: dataset,
            networkAnalysisId: convertProcessGroup(processGroup, datasetObj.networkAnalysis),
            integratorId: integrator,
            dsoId: dso,
            rebalancingIds: convertProcessGroup(processGroup, datasetObj.rebalancing),
            deviceDetectionModelIds: convertProcessGroup(processGroup, datasetObj.pvDetection),
            pvLoadCapacityModelIds: convertProcessGroup(processGroup, datasetObj.pvCapacity),
            pvPlannedLoadIds: convertProcessGroup(processGroup, datasetObj.plannedLoad),
            useCasePermissions: perms.map((perm: Permission) => perm.useCase as UseCasePermissions),
            processingTopoId: processGroup
              .filter((el: ProcessingGroup) => {
                return el.parent === datasetObj.topology2;
              })
              .map((el: ProcessingGroup) => el.processing),
          };
          dispatch(login({ loggedIn: true, dataContext }));
        }
      };
      loadData();
    }
  }, [dispatch, authenticated, token]);

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
