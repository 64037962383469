import i18n from 'i18n/i18n';

import { networkAnalytics, hourAnalytics, impactMetrics, percentAnalytics } from 'appConstants';
import { decimalToRoundedTenthPercent, convertToHour, roundToTenth } from 'helpers';

export const filterSensorsWithLocation = (sensors: Sensor[]) => {
  return sensors.filter((s: Sensor) => s.location?.coordinates?.length);
};

export const addAnalyticsToSecondarySub = (
  secSubstations: SecondarySubstation[],
  secSubstationsAnalytics: SecondarySubstationAnalytics[]
) => {
  return secSubstations.map((secSub: SecondarySubstation) => {
    const secSubAnalytics = secSubstationsAnalytics?.find(
      (secSubAnalytics) => secSubAnalytics.sensor === secSub.identifier
    );
    if (secSubAnalytics) {
      const secSubWithAnalyticsConvertedDate = Object.fromEntries(
        Object.entries(secSubAnalytics)
          .filter(([k]) => networkAnalytics.includes(k as keyof SecondarySubstationAnalytics))
          .map(([k, v]) => {
            /* Convert value to hour if time variable */
            if (
              hourAnalytics.includes(k as keyof SecondarySubstationAnalytics) &&
              typeof v === 'string'
            ) {
              return [k, convertToHour(v)];
            } else if (
              typeof v === 'number' &&
              percentAnalytics.includes(k as keyof SecondarySubstationAnalytics)
            ) {
              return [k, decimalToRoundedTenthPercent(v)];
            } else {
              return [k, typeof v === 'number' ? roundToTenth(v as number) : v];
            }
          })
      );
      return Object.assign(secSub, secSubWithAnalyticsConvertedDate);
    }
    return secSub;
  });
};

export const addPvMetricsToSecondarySub = (
  secSubstations: SecondarySubstation[],
  secSubPvMetrics: SecSubPvMetricsApi[]
) => {
  return secSubstations.map((secSub: SecondarySubstation) => {
    const secSubPvAnalytics = secSubPvMetrics?.find(
      (secSubPvMetric) => secSubPvMetric.sensor === secSub.identifier
    );
    if (secSubPvAnalytics) {
      const secSubWithImpactMetrics = Object.fromEntries(
        Object.entries(secSubPvAnalytics)
          .filter(([k]) => impactMetrics.includes(k as keyof SecSubPvMetricsApi))
          .map(([k, v]) => {
            if (typeof v !== 'string' && typeof v !== 'number') {
              return [k, v ? roundToTenth(v?.magnitude) : null];
            } else {
              return [k, v];
            }
          })
      );
      return Object.assign(secSub, secSubWithImpactMetrics);
    }
    return secSub;
  });
};

export const formatSensorsLocation = (sensors: Sensor[]) => {
  return sensors.map((s: Sensor) => {
    if (s.location?.coordinates?.length) {
      return {
        ...s,
        location: {
          type: s.location.type,
          /* Coordinates need to be reversed to match Leaflet convention */
          coordinates: [s.location.coordinates[1], s.location.coordinates[0]],
        },
      };
    } else {
      return s;
    }
  });
};

export const addExtraTopoDataToSmartMeter = (
  sensors: Sensor[],
  topologies: SimplifiedTopologyRow[],
  feeders: Sensor[]
): Sensor[] => {
  return sensors.map((s: Sensor) => {
    const indexTopologyRow = topologies.findIndex((value) => value.sensor === s.identifier);
    const feederName = feeders.find(
      (feeder) => feeder.identifier === topologies[indexTopologyRow]?.feeder
    )?.name;
    if (indexTopologyRow !== undefined) {
      return {
        ...s,
        feeder: feederName,
        phase1: topologies[indexTopologyRow]?.phase1,
        parent: topologies[indexTopologyRow]?.parentSensor,
      };
    } else {
      return s;
    }
  });
};

/* Concatenate an overview with a list of feeders to create the tabs in analysis
The Feeder are sorted by name */
export const createFeederTabs = (
  currentFeeders: SecondarySubstationFeeder[],
  activeSecondarySubstationId: string
) => {
  return [
    {
      feederId: activeSecondarySubstationId,
      feederName: 'tabs.overview',
    },
  ].concat(
    currentFeeders.map((feeder: SecondarySubstationFeeder) => {
      return {
        feederId: feeder.identifier,
        feederName: feeder.name,
      };
    })
  );
};

export const createStaticTabs = (key: string) => {
  const tabs: { name: string; identifier: string }[] = [
    { name: `${i18n.t('tabs.map')}`, identifier: 'Map' },
    {
      name: `${i18n.t('tabs.list')} ${i18n.t(`sensors_name.${key}`)}`,
      identifier: 'List',
    },
  ];
  return tabs;
};
