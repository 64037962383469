import { AnalyticsDefinition, Icon, SelectDropdown } from 'components/atoms';
import React, { useEffect, useState } from 'react';

import './info-dropdown.scss';
import { blinkTimeTransition } from 'appConstants/info';

type Props = {
  dropdownOptions: {
    key: string;
    translationKey: string;
    optionalTranslationKeys?: string[];
  }[];
  selectValue: (value: string, key: string) => void;
  name: string;
  label?: string;
  initialValue?: string | number;
  children?: JSX.Element;

  initialinfoValue?: string;
  positionInfo?: { width: string; left: string; right: string; top: string };
};

const InfoDropdown = ({
  dropdownOptions,
  selectValue,
  name,
  label,
  initialValue,
  children,
  initialinfoValue,
  positionInfo = { width: 'fit-content', left: 'auto', right: '-0.5rem', top: '3rem' },
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [infoColorClass, setInfoColorClass] = useState('info-icon-default');
  const [showInfo, setShowInfo] = useState(false);
  const [allowInfoBlink, setAllowInfoBlink] = useState(false);

  useEffect(() => {
    if (initialinfoValue) {
      setSelectedOption(initialinfoValue);
    } else {
      setSelectedOption(dropdownOptions[0].translationKey);
    }
  }, [initialinfoValue, dropdownOptions]);

  useEffect(() => {
    if (allowInfoBlink) {
      setInfoColorClass('info-icon-blink');
      setTimeout(() => {
        setInfoColorClass('info-icon-default');
      }, blinkTimeTransition.fadeIn + blinkTimeTransition.stay);
    }
  }, [allowInfoBlink, selectedOption]);

  const getDefinition = (value: string) => {
    setAllowInfoBlink(true);
    setSelectedOption((prev) => {
      const findSelectedOption = dropdownOptions.find((option) => option.key === value);
      if (findSelectedOption && findSelectedOption.key) {
        return findSelectedOption.key;
      } else {
        return prev;
      }
    });
  };

  return (
    <div className="info-dropdown-container">
      <div
        className=" is-flex is-grouped is-grouped-right 
     is-align-items-center is-relative info-dropdown"
      >
        <SelectDropdown
          isCompacted={true}
          dropdownOptions={dropdownOptions}
          selectValue={selectValue}
          getDefinition={(value: string) => getDefinition(value)}
          name={name}
          label={label}
          initialValue={initialValue}
          isInfofocus={showInfo}
        >
          {children}
        </SelectDropdown>

        <div
          onMouseEnter={() => setShowInfo(true)}
          onMouseLeave={() => setShowInfo(false)}
          className="container-icon"
        >
          <Icon name="info" classes={infoColorClass} />
        </div>

        {showInfo && (
          <div
            className="info-container"
            style={{
              width: positionInfo.width,
              left: positionInfo.left,
              right: positionInfo.right,
              top: positionInfo.top,
            }}
          >
            <AnalyticsDefinition analyticsKey={selectedOption} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoDropdown;
