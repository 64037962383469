import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import RedirectOnAuth from './RedirectOnAuth';
import AuthPrivateRoute from './AuthPrivateRoute';
import PermissionPrivateRoute from './PermissionPrivateRoute';

const Login = lazy(() => import('components/pages/Login/Login'));
const Mapping = lazy(() => import('components/pages/Mapping/Mapping'));
const Analysis = lazy(() => import('components/pages/Analysis/Analysis'));
const Rebalancing = lazy(() => import('components/pages/Rebalancing/Rebalancing'));
const Impact = lazy(() => import('components/pages/Impact/Impact'));
const NotFound = lazy(() => import('components/pages/NotFound/NotFound'));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RedirectOnAuth />,
    children: [{ path: '/', element: <Login /> }],
    errorElement: <NotFound />,
  },
  {
    path: '/',
    element: <AuthPrivateRoute />,
    errorElement: <NotFound />,
    children: [
      {
        path: 'mapping',
        element: (
          <PermissionPrivateRoute requiredPermission="mapping">
            <Mapping />
          </PermissionPrivateRoute>
        ),
      },
      {
        path: 'analysis',
        element: (
          <PermissionPrivateRoute requiredPermission="analysis">
            <Analysis />
          </PermissionPrivateRoute>
        ),
      },
      {
        path: 'rebalancing',
        element: (
          <PermissionPrivateRoute requiredPermission="rebalancing">
            <Rebalancing />
          </PermissionPrivateRoute>
        ),
      },
      {
        path: 'impact',
        element: (
          <PermissionPrivateRoute requiredPermission="impact">
            <Impact />
          </PermissionPrivateRoute>
        ),
      },
    ],
  },
]);
