import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Header } from 'components/organisms';
import Layout from 'components/organisms/Layout/Layout';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthPrivateRoute = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();

  if (!keycloak.authenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <Layout>
      <Header />
      <Outlet />
    </Layout>
  );
};

export default AuthPrivateRoute;
