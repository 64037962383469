import React, { ChangeEvent } from 'react';
import InputField from './InputField';
import { roundToTenth } from 'helpers/general';

type Props = {
  inputs: { label: string; value: string | number; min?: number; classes?: string }[];
  onInputChange?: (
    e: ChangeEvent<HTMLInputElement>,
    input: { label: string; value: string | number; min?: number }
  ) => void;
};

const InputFieldGroup = ({ inputs, onInputChange }: Props) => {
  return (
    <div className="field is-field-group">
      <div className="field-body">
        {inputs.map((input) => {
          return (
            <InputField
              key={input.label}
              label={input.label}
              value={roundToTenth(+input.value)}
              min={input.min}
              classes={input.classes}
              {...(onInputChange && {
                onInputChange: (e: ChangeEvent<HTMLInputElement>) =>
                  onInputChange && onInputChange(e, input),
              })}
            />
          );
        })}
      </div>
    </div>
  );
};

export default InputFieldGroup;
