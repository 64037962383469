import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { asTemplateString } from 'helpers';
import { useAppSelector } from 'hooks';
import { useCases } from 'appConstants';

const Navigation = () => {
  const { t } = useTranslation();
  const { useCasePermissions } = useAppSelector((state) => state.session.dataContext);

  const isLinkEnabled = (link: UseCasePermissions) => {
    return useCasePermissions?.includes(link) ? true : false;
  };

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, navName: UseCasePermissions) => {
    if (!isLinkEnabled(navName)) e.preventDefault();
  };

  const isActive = ({ isActive }: { isActive: boolean }) => {
    let classes = 'is-flex-centered p-2 px-2 nav-link is-size-6bis normal-line-height';
    classes += isActive ? ' button is-primary has-text-weight-bold is-hovered' : '';
    return classes;
  };

  return (
    <nav className="is-flex-centered">
      <ul className="is-flex-centered is-family-primary has-text-weight-normal ml-6">
        {useCases.map((useCase) => (
          <li
            className={`is-flex-centered mx-2 ${isLinkEnabled(useCase) ? '' : 'has-tooltip'}`}
            key={useCase}
          >
            <NavLink to={useCase} className={isActive} onClick={(e) => handleClick(e, useCase)}>
              {t(asTemplateString(`${useCase}.navbar`))}
            </NavLink>
            {!isLinkEnabled(useCase) && (
              <div className="tooltip-text bottom-tooltip">{'Use case not available'}</div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
