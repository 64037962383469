import React, { useMemo } from 'react';

import { Gauge } from 'components/atoms';

type Props = {
  capacities: NormalizedCapacities | undefined;
  stripped?: boolean;
  classes?: string;
  cursor?: boolean;
};

const CapacityCell = ({ capacities, classes = '', stripped = false, cursor = false }: Props) => {
  const segments: [number, string][] | null = useMemo(() => {
    return capacities
      ? Object.entries(capacities)
          .filter(([key, value]) => value && key !== 'maxBeforeSimulation')
          .map(([key, value]) => {
            if (stripped && key === 'residualMax') {
              // we have to create a new gauge segment to know where the strip is
              return [value, `stripped_${key}`];
            } else {
              return [value, key];
            }
          })
      : null;
  }, [capacities, stripped]);
  return (
    <div className={`capacity-cell ${classes}`}>
      {segments && <Gauge segments={segments} />}
      {cursor && segments && (
        <div
          className="cursor"
          style={{
            width: `${capacities?.maxBeforeSimulation}%`,
          }}
        ></div>
      )}
    </div>
  );
};

export default CapacityCell;
