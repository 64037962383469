/*
 * This file is legacy code and contains all the routes of the application.
 * It is still used in file:
    - UseCaseActions (src/components/molecules/UseCaseActions/UseCaseActions.tsx).
  * It is recommended to use the new way of defining routes in the router file.
 */
export const routes = {
  home: {
    path: '/',
  },
  mapping: {
    path: '/mapping',
  },
  analysis: {
    path: '/analysis',
  },
  impact: {
    path: '/impact',
  },
  rebalancing: {
    path: '/rebalancing',
  },
};

export default routes;
