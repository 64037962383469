import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-leaflet';
import { Icon } from 'components/atoms';

type Props = {
  onSend: (e: string) => void;
  isOnMap: boolean;
  showNoSubstationMessage: boolean;
  setShowNoSubstationMessage: (value: boolean) => void;
};

const SearchBar = ({
  onSend,
  isOnMap = false,
  showNoSubstationMessage,
  setShowNoSubstationMessage,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();
  const map = useMap();

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      if (!searchValue) {
        return;
      }
      onSend(searchValue);
      setSearchValue('');
    }
  };

  function handleClickSubmit(value: string) {
    if (!value) {
      setShowNoSubstationMessage(false);
      return;
    }
    onSend(value);
    setSearchValue('');
  }

  const handleMouseEnter = () => {
    map.dragging.disable();
  };

  const handleMouseLeave = () => {
    map.dragging.enable();
  };

  return (
    <div className="search-bar-container">
      <div className="field has-addons search-bar-control leaflet-control">
        <div className="control has-icons-left input-search">
          <input
            className="input is-rounded"
            type="text"
            autoComplete="off"
            placeholder={t('map.map_search_placeholder')}
            name="search"
            value={searchValue}
            onKeyDown={(e) => handleKeyDown(e.key)}
            onChange={(e) => setSearchValue(e.target.value)}
            {...(isOnMap && { onMouseEnter: () => handleMouseEnter() })}
            {...(isOnMap && { onMouseLeave: () => handleMouseLeave() })}
          />
          <span className="icon is-left">
            <Icon name="search" />
          </span>
        </div>
        <div className="control">
          <button
            type="submit"
            className="button is-rounded"
            onClick={() => handleClickSubmit(searchValue)}
          >
            {t('map.search')}
          </button>
        </div>
      </div>
      {showNoSubstationMessage && (
        <p className="searchbar-message">{t('map.map_search_no_results')}</p>
      )}
      {/* {showEmptyValueMessage && <p className="searchbar-message">Please fill in the field</p>}
       */}
    </div>
  );
};

export default SearchBar;
