import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  logo: string;
  width?: string | number;
  height?: string | number;
};

const Logo = ({ logo, width = 'auto', height }: Props) => {
  const { t } = useTranslation();

  return (
    <img
      className="logo"
      src={logo}
      alt={`${t('corporate.company_name')} ${t('corporate.logo')}`}
      style={{ width, height }}
    />
  );
};

export default Logo;
